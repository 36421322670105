import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../assets/styles/components/blog-tile.scss';

const Blog = ({ data, pageContext }) => {
  const page = data.datoCmsBlogPage;
  const posts = data.allDatoCmsPost.edges;

  const blogTile = (post) => {
    const image = getImage(post.thumbnail);
    return (
      <Link to={`/blog/${post.slug}`} className="blog-tile">
        <GatsbyImage className="thumbnail" image={image} alt="post thumbnail" />
        <div className="blog-content">
          <h2>{post.title}</h2>
          <small className="date">
            Published on
            {' '}
            {post.publishDate}
          </small>
          {// eslint-disable-next-line react/no-danger
            <div className="excerpt" dangerouslySetInnerHTML={{ __html: post.excerpt }} />
          }
          <span className="cta-text">Read more</span>
        </div>
      </Link>
    );
  };

  const getLink = (index) => {
    let url = '/blog';
    if (index > 0) {
      url += '/';
      url += `page-${index + 1}`;
    }
    return url;
  };

  const pagination = () => {
    const content = [];
    content.push(
      <Link
        className={pageContext.currentPage === 1 ? 'disable' : ''}
        to={getLink(pageContext.currentPage - 2)}
      >
        Prev
      </Link>,
    );
    content.push(
      <Link
        className={pageContext.currentPage === pageContext.numPages ? 'disable' : ''}
        to={getLink(pageContext.currentPage)}
      >
        Next
      </Link>,
    );
    return content;
  };

  return (
    <Layout>
      {page.seo && <SEO title={page.seo.title} description={page.seo.description} />}
      {posts
        && posts.map((post) => {
          return blogTile(post.node);
        })}
      <div className="pagination">
        {pageContext.numPages > 1 && pagination()}
        <small>
          Page
          {' '}
          {pageContext.currentPage}
          {' '}
          of
          {' '}
          {pageContext.numPages}
        </small>
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query blog($skip: Int!, $limit: Int!) {
    allDatoCmsPost(sort: { fields: publishDate, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          excerpt
          content
          title
          publishDate(formatString: "D/MM/YYYY")
          slug
          thumbnail {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    datoCmsBlogPage {
      seo {
        title
        description
      }
    }
  }
`;

Blog.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
};
